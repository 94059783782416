import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  html {

  }

  body {
    font-family: text, sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #6E514C;
  }

  * {
    box-sizing: border-box;
  }

  *::selection {
    background: #E2B3AA;
    color: white;
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid;
  }

  a[target=_blank] {
    cursor: ne-resize;
  }

  h1, h2, h3, p {
    font-weight: normal;
    position: relative;
    z-index: 100;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1, h2, h3 {
    font-family: display, sans-serif;
  }

  h2, h3, p {
    margin: 0 0 0.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }

  label {
    display: block;
  }

  button {
    touch-action: manipulation;
  }

  .fade-in {
    transform: translateY(2rem);
    opacity: 0;
  }

  .will-fade {
    transition: transform 1s, opacity 1s;
    will-change: transform, opacity;
  }
`;

export default GlobalStyles;
